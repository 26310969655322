import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import RightSidebar from "../../components/CommonForBoth/RightSidebar";
import {
    changeLayout,
    changeSidebarTheme,
    changeSidebarThemeImage,
    changeSidebarType,
    changeTopbarTheme,
    changeLayoutWidth,
    showRightSidebarAction,
    toggleLeftmenuCollapse
} from "../../store/actions";
import APP_CONFIG from "../../APP_CONFIG";

const VerticalLayout = (props) => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const dispatch = useDispatch();

    const {
        isPreloader,
        leftSideBarThemeImage,
        layoutWidth,
        leftSideBarType,
        topbarTheme,
        showRightSidebar,
        leftSideBarTheme,
        leftMenuCollapsed
    } = useSelector((state) => ({
        isPreloader: state.Layout.isPreloader,
        leftSideBarThemeImage: state.Layout.leftSideBarThemeImage,
        leftSideBarType: state.Layout.leftSideBarType,
        layoutWidth: state.Layout.layoutWidth,
        topbarTheme: state.Layout.topbarTheme,
        showRightSidebar: state.Layout.showRightSidebar,
        leftSideBarTheme: state.Layout.leftSideBarTheme,
        leftMenuCollapsed: state.Layout.leftMenuCollapsed
    }));

    const toggleMenuCallback = () => {
        if (leftSideBarType === "default") {
            dispatch(changeSidebarType("condensed", isMobile));
        } else if (leftSideBarType === "condensed") {
            dispatch(changeSidebarType("default", isMobile));
        }
    };

    //hides right sidebar on body click
    const hideRightbar = (event) => {
        var rightbar = document.getElementById("right-bar");
        //if clicked in inside right bar, then do nothing
        if (rightbar && rightbar.contains(event.target)) {
            return;
        } else {
            //if clicked in outside of rightbar then fire action for hide rightbar
            dispatch(showRightSidebarAction(false));
        }
    };

    useEffect(() => {
        //init body click event fot toggle rightbar
        document.body.addEventListener("click", hideRightbar, true);

        if (isPreloader === true) {
            document.getElementById("preloader").style.display = "block";
            document.getElementById("status").style.display = "block";

            setTimeout(function () {
                document.getElementById("preloader").style.display = "none";
                document.getElementById("status").style.display = "none";
            }, 2500);
        } else {
            document.getElementById("preloader").style.display = "none";
            document.getElementById("status").style.display = "none";
        }
    }, [isPreloader]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        dispatch(changeLayout("vertical"));
    }, [dispatch]);

    useEffect(() => {
        if (leftSideBarTheme) {
            dispatch(changeSidebarTheme(leftSideBarTheme));
        }
    }, [leftSideBarTheme, dispatch]);

    useEffect(() => {
        if (leftSideBarThemeImage) {
            dispatch(changeSidebarThemeImage(leftSideBarThemeImage));
        }
    }, [leftSideBarThemeImage, dispatch]);

    useEffect(() => {
        if (layoutWidth) {
            dispatch(changeLayoutWidth(layoutWidth));
        }
    }, [layoutWidth, dispatch]);

    useEffect(() => {
        if (leftSideBarType) {
            dispatch(changeSidebarType(leftSideBarType));
        }
    }, [leftSideBarType, dispatch]);

    useEffect(() => {
        if (topbarTheme) {
            dispatch(changeTopbarTheme(topbarTheme));
        }
    }, [topbarTheme, dispatch]);


    return (
        <>
            <div id="preloader">
                <div id="status">
                    <div className="spinner-chase">
                        <div className="chase-dot" />
                        <div className="chase-dot" />
                        <div className="chase-dot" />
                        <div className="chase-dot" />
                        <div className="chase-dot" />
                        <div className="chase-dot" />
                    </div>
                </div>
            </div>

            <div id="layout-wrapper">
                <Header toggleMenuCallback={toggleMenuCallback} />
                <Sidebar theme={leftSideBarTheme} type={leftSideBarType} isMobile={isMobile} />
                <div className="main-content">{props.children}</div>
                {APP_CONFIG.IS_FOOTER && <Footer />}
            </div>
            {showRightSidebar ? <RightSidebar /> : null}
        </>
    );
};

export default withRouter(VerticalLayout);
