import { injectBaseConstantMethods } from "./BaseConstants";

const Plans = {
    Free: "Free",
    Monthly: "Monthly",
    Yearly: "Yearly"
};

const displayTextKeys = {
    [Plans.Free]: "Free",
    [Plans.Monthly]: "Monthly",
    [Plans.Yearly]: "Yearly",
};

const labelClass = {
    [Plans.Free]: "plan-free plan-boxes",
    [Plans.Monthly]: "plan-monthly plan-boxes",
    [Plans.Yearly]: "plan-yearly plan-boxes"
};

export default injectBaseConstantMethods(Plans, displayTextKeys, labelClass);
