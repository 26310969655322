export const errorMessages = {

}

export const successMessages = {
  OTPSent: "An OTP has been send to your email address!",
  OTPReSent: "An OTP has been resent to your email address",
  passwordReset: "Password has been reset successfully!",
  loginSuccess: "Login successfully!"
}

export const validationMessages = {
  emailRequired: "Enter email address",
  emailValidation: "Enter a valid email address",
  shortEmail: "Too short email!",
  longEmail: "Too long email!",
  newPasswordRequired: "Enter new password",
  passwordMinLength: "Password should be minimum 6 characters long",
  passwordMaxLength: "Password should be between 6 to 25 characters",
  passwordValidation: "Password must contains characters, special characters and numbers",
  passwordMatch: "Please make sure your passwords match",
  confirmPassword: "Please enter your confirm password",
  enterPassword: "Enter password"
}