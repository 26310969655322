export const findStatusFromNumericVal = (val) => {
  switch(val){
    case 2:
      return "ACTIVE";
    case 3:
      return "INACTIVE";
    default:
      return 'all';
  }
}
export const findPlanFromNumericVal = (val) => {
  switch(val){
    case 2:
      return "free";
    case 3:
      return "premium";
    default:
      return 'all';
  }
}