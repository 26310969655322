import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Label } from "reactstrap";
import { Redirect } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import toast from "react-hot-toast";

import AuthWrapper from "./AuthWrapper";
import { TextInput, PasswordInput } from "../../components/Input";
import { CustomButton } from "components/CustomButton";
import { APP_ROUTES } from "../../helpers/routeHelpers";
import { login } from "store/actions";
import PageTitles from "../../constants/PageTitles";
import Headings from "./Headings";
import { successMessages, validationMessages } from "./SuccessErrorMessages";
import typography from "./typography.js";

const Login = (props) => {
    let {} = props;
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    let isAuthenticated = useSelector((state) => state.userAuth.isAuthenticated);

    const formik = useFormik({
        // enableReinitialize: true,
        initialValues: {
            email: "",
            password: ""
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email(validationMessages.emailValidation)
                .required(validationMessages.emailRequired)
                .min(6, validationMessages.shortEmail)
                .max(50, validationMessages.longEmail)
                .matches(
                    /([A-Z|a-z|0-9](\.|_){0,1})+[A-Z|a-z|0-9]\@([A-Z|a-z|0-9])+((\.){0,1}[A-Z|a-z|0-9]){2}\.[a-z]{2,3}$/,
                    validationMessages.emailValidation
                ),
            password: Yup.string().required(validationMessages.enterPassword)
        }),
        onSubmit: (values) => {
            handleFormSubmit(values);
        }
    });

    const handleFormSubmit = async (values) => {
        const { email, password } = values;
        try {
            setIsLoading(true);
            let res = await dispatch(
                login({
                    email,
                    password,
                    type: typography.TYPE_ADMIN,
                    // token: " "
                })
            );
            toast.success(successMessages.loginSuccess);
        } finally {
            setIsLoading(false);
        }
    };

    if (isAuthenticated) {
        return <Redirect to={APP_ROUTES.DASHBOARD} />;
    }

    return (
        <>
            <Helmet>
                <title>{PageTitles.LOGIN}</title>
            </Helmet>
            <AuthWrapper>
                <div>
                    <Headings title={typography.HEY_THERE} subText={typography.WELCOME_BACK} isLogin={true} />
                    <form className="mt-5" onSubmit={formik.handleSubmit}>
                        <Label>{typography.EMAIL}</Label>
                        <div className="mb-4">
                            <TextInput
                                autoComplete={"true"}
                                placeholder={typography.ENTER_EMAIL}
                                name={"email"}
                                type="email"
                                className="hide-default-icon"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                                autoFocus
                            />
                            {formik.touched.email && formik.errors.email ? (
                                <span className="error">{formik.errors.email}</span>
                            ) : null}
                        </div> 

                        <Label>{typography.PASSWORD}</Label>
                        <div className="mb-4">
                            <PasswordInput
                                placeholder={typography.ENTER_PASSWORD}
                                className="hide-default-icon"
                                name="password"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.password}
                            />
                            {formik.touched.password && formik.errors.password ? (
                                <span className="error">{formik.errors.password}</span>
                            ) : null}
                        </div>
                        <div>
                            <Link
                                className="d-flex align-items-center justify-content-end my-4"
                                to={APP_ROUTES.FORGET_PASSWORD}
                            >
                                <p className="clr-theme-primary font-family-source-sans-600 forgot-pass-link"> Forgot Password? </p>
                            </Link>
                        </div>

                        <CustomButton
                            loading={isLoading}
                            type="submit"
                            color="primary"
                            title={typography.SIGN_IN}
                            className="w-100 "
                        />
                    </form>
                </div>
            </AuthWrapper>
        </>
    );
};

export default withRouter(Login);
