import typography from "./typography"
export const findOutClassStatBoxes = (value) => {
  switch(value){
    case typography.BLS_THERAPY_COUNT:
      return "primary-gradient-bg"
    case typography.TOTAL_RESPONSES:
      return "bg-orange"
    case typography.AVERAGE_RATING:
      return "bg-blue"
    default:
      return ""
  }
}

export const findBarPercentage = (percentage) => {
  if(!percentage) return 0
  return Math.round((Number(percentage)/10)*100)
}