import searchIcon from '../../assets/images/app/common/aou-search-icon.svg'
import { TextInput } from '../Input'

export default function SearchInput (
props
) {
  return(
    <div className='search-input-con'>
      <TextInput
        icon={searchIcon}
        containerClasses="search-text-con"
        iconClasses="search-icon"
        inputClasses="search-input-field"
        {...props}
      />
    </div>

  )
}