import React, { useState } from "react";

import TextInput from "./TextInput";

let SHOW_ICON = <span className="bx bx-show"></span>;
let HIDE_ICON = <span className="bx bxs-hide"></span>;
let TYPE_PASSWORD = "password";
let TYPE_TEXT = "text";

const PasswordInput = ({ type, className="pe-4", ...rest }) => {
    const [customType, setCustomType] = useState(TYPE_PASSWORD);

    const handlePasswordToggle = () => {
        if (customType == TYPE_TEXT) {
            setCustomType(TYPE_PASSWORD);
        } else {
            setCustomType(TYPE_TEXT);
        }
    };

    let icon = customType == TYPE_PASSWORD ? SHOW_ICON : HIDE_ICON
    return (
        <>
            <TextInput type={customType} inputClasses={`${className} pe-4`} isPassword={true} iconClasses="password-icon" onIconClick={handlePasswordToggle} icon={icon} {...rest} />
                {/* <div className="input-icon cursor-pointer" onClick={handlePasswordToggle}>
                    {customType == TYPE_PASSWORD ? SHOW_ICON : HIDE_ICON}
                </div> */}
            {/* </TextInput> */}
        </>
    );
};

export default PasswordInput;
