import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Container, Row } from "reactstrap";

import { Heading } from "components/Heading";
import { PageHeadings } from "../../constants";
import StatBoxes from './StatBoxes';
import BLSTable from "./BLSTable";
import { PageTitles } from "../../constants";

const BLSFeedback = ({}) => {

    return (
        <>
            <div className="page-content">
                <Helmet>
                    <title>{PageTitles.BLS_FEEDBACK}</title>
                </Helmet>
                <Container fluid className="bls-feedback">
                    <Heading heading={PageHeadings.BLS_FEEDBACK} />
                    <Row>
                        <StatBoxes />
                    </Row>
                    <Row>
                        <BLSTable />
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default BLSFeedback;
