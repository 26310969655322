import React from "react";

export default ({ component: Component, title = "Channel Source" }) => {
    const withTitle = (props) => {
        document.title = title + " - Channel Source";
        return <Component {...props} />;
    };

    return withTitle;
};
