import { RatingBarInput } from "../../components/RatingBarInput";
import { hasData } from "../../utils/commonUtils";
import { convertDateTime, dateTimeFormat } from "../../utils/dateFormatUtils";
import typography from "./typography";

export default function Details({
  feedbackData={}
}){

  // find answer for all type of questions (text as well as options)
  const findAnswer = (el) => {
    if(el?.type === "OPTIONS"){
      let answer = feedbackData?.userFeedbackFormAnswers?.filter(answer => answer?.questionId === el?.id)[0];
      if(answer){
        return el?.answerOptions?.filter(option => option?.id === answer?.answerOptionId)[0]?.content;
      }
    }else{
      return el?.answerText
    }
  }

  return(
    <>
      <div className="bls-feedback-con px-5">
        <h1 className="heading standard-heading-22-600">{typography.FEEDBACK_DETAILS}</h1>
        <div className="after-heading">
          <div className="name-con">
            <p className="standard-typo-14-400">Customer Name: <span >{feedbackData?.user?.name || "-"}</span></p>
          </div>
          <div>
            <p className="standard-typo-14-400">Submitted On: <span >{convertDateTime({date: feedbackData?.feedbackFormSubmittedAt, customFormat: dateTimeFormat.monthLetterFormat}) || "-"}</span></p>
          </div>
        </div>
        <div className="question-answer-con">
          {feedbackData?.feedbackForm?.questions.length > 0 && feedbackData?.feedbackForm?.questions.map(el =>
            (<div key={el.id}>
              <p className="question">{el?.content}</p>
              <p className="answer">{findAnswer(el) }</p>
            </div>)
          )}
          
          {/*<p className="question">On a scale of 1-10, how much did the BLS and affirmation help in managing your distressing mood?</p>
          <RatingBarInput 
          rating={5}
          innerClass="inner-con"
          containerClass="outer-con"
          mainClass="answer"
          icon={true}/>*/}
        </div>
      </div>
    </>
  )
}