export const ME_REQUEST = "ME_REQUEST";
export const ME_SUCCESS = "ME_SUCCESS";
export const ME_ERROR = "ME_ERROR";
export const SET_TOKEN = "SET_TOKEN";
export const SIGN_OUT = "SIGN_OUT";

export const LOCATION_LISTING_REQUEST = "LOCATION_LISTING_REQUEST";
export const LOCATION_LISTING_SUCCESS = "LOCATION_LISTING_SUCCESS";
export const LOCATION_LISTING_FAILURE = "LOCATION_LISTING_FAILURE";

export const ADD_LOCATION_REQUEST = "ADD_LOCATION_REQUEST";
export const ADD_LOCATION_SUCCESS = "ADD_LOCATION_SUCCESS";
export const ADD_LOCATION_FAILURE = "ADD_LOCATION_FAILURE";

export const DELETE_LOCATION_LOCAL = "DELETE_LOCATION_LOCAL";

export const UPDATE_LOCATION_REQUEST = "UPDATE_LOCATION_REQUEST";
export const UPDATE_LOCATION_SUCCESS = "UPDATE_LOCATION_SUCCESS";
export const UPDATE_LOCATION_FAILURE = "UPDATE_LOCATION_FAILURE";

export const DELETE_LOCATION_REQUEST = "DELETE_LOCATION_REQUEST";
export const DELETE_LOCATION_SUCCESS = "DELETE_LOCATION_SUCCESS";
export const DELETE_LOCATION_FAILURE = "DELETE_LOCATION_FAILURE";

export const FRANCHISEE_LISTING_REQUEST = "FRANCHISEE_LISTING_REQUEST";
export const FRANCHISEE_LISTING_SUCCESS = "FRANCHISEE_LISTING_SUCCESS";
export const FRANCHISEE_LISTING_FAILURE = "FRANCHISEE_LISTING_FAILURE";

export const UPDATE_FRANCHISEE_REQUEST = "UPDATE_FRANCHISEE_REQUEST";
export const UPDATE_FRANCHISEE_SUCCESS = "UPDATE_FRANCHISEE_SUCCESS";
export const UPDATE_FRANCHISEE_FAILURE = "UPDATE_FRANCHISEE_FAILURE";

export const DELETE_FRANCHISEE_REQUEST = "DELETE_FRANCHISEE_REQUEST";
export const DELETE_FRANCHISEE_SUCCESS = "DELETE_FRANCHISEE_SUCCESS";
export const DELETE_FRANCHISEE_FAILURE = "DELETE_FRANCHISEE_FAILURE";

export const ADMIN_LISTING_REQUEST = "ADMIN_LISTING_REQUEST";
export const ADMIN_LISTING_SUCCESS = "ADMIN_LISTING_SUCCESS";
export const ADMIN_LISTING_FAILURE = "ADMIN_LISTING_FAILURE";

export const UPDATE_ADMIN_LISTING_REQUEST = "UPDATE_ADMIN_LISTING_REQUEST";
export const UPDATE_ADMIN_LISTING_SUCCESS = "UPDATE_ADMIN_LISTING_SUCCESS";
export const UPDATE_ADMIN_LISTING_FAILURE = "UPDATE_ADMIN_LISTING_FAILURE";
