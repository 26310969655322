import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Container} from "reactstrap";
import { useHistory } from "react-router-dom";

import { Heading } from "components/Heading";
import { PageHeadings, SortOrder, PageTitles, Plans } from "../../../constants";
import typography from "../typography";
import Header from "./Header"; 
import DataTable from "../../../components/DataTable/DataTable";
import { UserAvatar } from "../../../components/Avatar";
import { getMediaPath } from "../../../utils/mediaUtils";
import APP_CONFIG from "../../../APP_CONFIG";
import { truncateString } from "../../../utils/commonUtils";
import viewIcon from "../../../assets/images/app/common/aou-view-icon.svg";
import { ToggleInput } from "../../../components/ToggleInput";
import { Pagination } from '../../../components/Pagination';
import { APP_ROUTES } from "../../../helpers/routeHelpers";
import { ConfirmationModal } from "../../../components/CustomModal";
import ModalSuccessImage from '../../../assets/images/app/common/aou-modal-success-img.svg'
import { customerListingApi, userUpdateStatus } from "../../../api/api.service";
import { useLocalPaginatedRequest } from '../../../hooks/useLocalPaginatedRequest';
import { findPlanFromNumericVal, findStatusFromNumericVal } from "../utils";
import toast from "react-hot-toast";

const SORTABLE_COLUMNS = {
  [typography.NAME]: "name",
};


const Listing = ({}) => {
  const history = useHistory()

  const [isModalActive, setIsModalActive] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [isStatusUpdating, setIsStatusUpdating] = useState(false)
  const [filters, setFilters] = useState({status: 1, plan: 1})
  
  const [currentSortColumn, setCurrentSortColumn] = useState(null);
  const [currentSortOrder, setCurrentSortOrder] = useState(null);
  const {
    isFetching,
    page,
    total,
    data,
    searchText,
    refetch,
    handlePageClick,
    onChangePageSize,
    pageSize,
    handleSearchChange,
    setPageToOne
  } = useLocalPaginatedRequest(
    customerListingApi,
    {
      ...(filters.status !== 1 && { status: findStatusFromNumericVal(filters.status) }),
      ...(filters.plan !== 1 && {plan: findPlanFromNumericVal(filters.plan)}),
      ...(currentSortColumn &&
        currentSortOrder && { column: currentSortColumn, direction: currentSortOrder }),
      ...({type: typography.USER})
    },
    [filters.plan, filters.status, currentSortOrder]
    
  )

  const handleStatusUpdate = (data) => {
    setSelectedUser(data);
    setIsModalActive(!isModalActive)
  }

  const updateUserStatus = async () => {
    setIsModalActive(!isModalActive)
    try {
      setIsStatusUpdating(true);
      let statusToUpdated = selectedUser?.status === typography.ACTIVE_CAPS ? typography.INACTIVE_CAPS : typography.ACTIVE_CAPS;
      let dataBody = {
        id: selectedUser?.id,
        bodyData: {status: statusToUpdated}
      }
      let resp = await userUpdateStatus(dataBody);
      toast.success("Status has been updated successfully!");
      data.forEach(el => {
        if(el.id === selectedUser?.id){
          el.status = statusToUpdated;
        } 
      })
    }finally {
      setIsStatusUpdating(false)
    }
  }
  
  const handleSortClick = (item) => {
      setCurrentSortColumn(item.sortColumn);
      setCurrentSortOrder((prev) =>
          prev === SortOrder.ASC || !prev ? SortOrder.DESC : SortOrder.ASC
      );
  };

  const handleDetailsView = (id) => {
    history.push(`${APP_ROUTES.CUSTOMER_DETAILS}/${id}`)
  }

  const handleFilterChange = (value, type) => {
    setPageToOne();
    setFilters({...filters, [type]: value}) 
  }


  return (
      <>
          <div className="page-content">
              <Helmet>
                  <title>{PageTitles.CUSTOMER_MANAGEMENT}</title>
              </Helmet>
              <Container fluid className="bls-feedback customer-management">
                  <Heading heading={PageHeadings.CUSTOMER_MANAGAMENT} />
                  <Header
                  statusFilterVal={filters.status}
                  filterUpdateHandler={handleFilterChange}
                  planFilterVal={filters.plan}
                  searchTextVal={searchText}
                  searchValUpdateHandler={handleSearchChange}
                    />
                  <DataTable
                  data={data}
                  loading={isFetching}
                  currentSortOrder={currentSortOrder}
                  currentSortColumn={currentSortColumn}
                  onSortClick={handleSortClick}
                  config={[
                    {
                        sortColumn: SORTABLE_COLUMNS[typography.NAME],
                        isSortable: true,
                        title: typography.NAME,
                        render: (data) => (
                            <div className="d-flex align-items-center">
                                <UserAvatar
                                    imgSrc={
                                        // getMediaPath(data.iconUrl) ||
                                        data?.profilePicture?.path ||
                                        APP_CONFIG.IMAGES.AVATAR_PLACEHOLDER
                                    }
                                    altText={"avatar"}
                                />
                                <span className="ms-2 standard-typo-14-400">{truncateString(data?.name, 30) || "-"}</span>

                            </div>
                        )
                    },
                    {
                        isSortable: false,
                        title: typography.EMAIL,
                        render: (data) => <div className=" standard-typo-14-400">{data?.email || "-"}</div>
                    },
                    {
                      isSortable: false,
                      title: typography.PHONE,
                      render: (data) => <div className=" standard-typo-14-400">{data?.phone || "-"}</div>
                    },
                    {
                      isSortable: false,
                      title: typography.PLAN,
                      render: (data) => 
                      <div className="">
                        <div className={` standard-typo-14-400  ${Plans.getLabelClass(data?.subscriptionTypes)}`}>{Plans.getDisplayTextKey(data?.subscriptionTypes) || "-"}</div>
                      </div>
                    }, 
                    {
                      isSortable: false,
                      title: typography.STATUS,
                      render: (data) =>
                      <div className="">
                        <ToggleInput 
                        isChecked={data?.status !== typography.INACTIVE_CAPS}
                        trueContent= {typography.ACTIVE}
                        falseContent={typography.INACTIVE}
                        toggleHandler={handleStatusUpdate}
                        userId = {data}
                        isLoading={isStatusUpdating}
                        />          
                      </div>        
                    },
                    {
                        title: typography.ACTION,
                        render: (data) => {
                            return (
                                <div className=" d-flex">
                                    <div className="view-form-icon-con" onClick={(e) => handleDetailsView(data.id)}>
                                    <img
                                        src={viewIcon}
                                        alt={"view icon"}
                                        role="button"
                                    />
                                    </div>
                                </div>
                            );
                        }
                    }
                  ]} />
                  <Pagination
                  onPageClick={handlePageClick}
                  currentPage={page}
                  totalData={total}
                  handleChange={(e) => onChangePageSize(e)}
                  rowLimit={pageSize}
                  />
              </Container>
          </div>
          <ConfirmationModal
              isOpen={isModalActive}
              hasCustomConfig
              size="md"
              onNo={() => setIsModalActive(false)}
              onYes={updateUserStatus}
              customTitle={typography.STATUS_UPDATE_CONFIRMATION_MESSAGE}
              customYesBtnText="Yes"
              customNoBtnText="No"
              customImageSrc={ModalSuccessImage}
              />
      </>
  );
};

export default Listing;
