import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { useHistory, useParams } from "react-router-dom";

import {HeadingWithBackArrow} from "../../../components/HeadingWithBackArrow";
import { LoaderFallbackUI } from "../../../components/LoaderFallbackUI";
import { findOutAgeFromDate, getApiFetch, hasData } from "../../../utils/commonUtils";
import typography from "../typography";
import { Plans } from "../../../constants";
import { GetUserById, userUpdateStatus } from "../../../api/api.service";
import { APP_ROUTES } from "../../../helpers/routeHelpers";
import { ConfirmationModal } from "../../../components/CustomModal";
import ModalSuccessImage from '../../../assets/images/app/common/aou-modal-success-img.svg'
import { profileBioConfig } from "./data";
import { convertDateTime, dateTimeFormat } from "../../../utils/dateFormatUtils";
import APP_CONFIG from "../../../APP_CONFIG";

const toggleConfig = {
  trueContent: typography.ACTIVE,
  falseContent: typography.INACTIVE,
}

const GenderValuesWithSpaces = {
  RatherNotSay: "Rather Not Disclose",
  GenderFluid: "Gender Fluid",
  Female: "Female",
  Male: "Male"
}

const customFormat = dateTimeFormat.monthLetterFormat;
export default function Biography () {
  const { id } = useParams()
  const history = useHistory()

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [userBioData, setUserBioData] = useState([]);
  const [isModalActive, setIsModalActive] = useState(false)
  const [isStatusUpdating, setIsStatusUpdating] = useState(false)

  const backButtonHandler = () => {
    history.push(APP_ROUTES.CUSTOMER_MANAGEMENT)
  }

  useEffect(() => {
  // fetching api
  if(!hasData(data) && !loading){
    getApiFetch(setLoading, setData, GetUserById, id);
  }

  // updating data
  if(hasData(data)){
    profileBioConfig.forEach( el => {
      switch(el.key){
        case "dateOfBirth":
          el['value'] = convertDateTime({date: data[el.key], customFormat})
          break;
        case "age":
          el['value'] = findOutAgeFromDate(data["dateOfBirth"])
          break;
        default: 
          el['value'] = data[el.key]        
      }
    })
    setUserBioData(profileBioConfig)
  }
  }, [id, data]);

  const updateUserStatus = async () => {
    modalToggleHandler()
    try {
      setIsStatusUpdating(true);
      let statusToUpdated = data?.status === typography.ACTIVE_CAPS ? typography.INACTIVE_CAPS : typography.ACTIVE_CAPS;
      let dataBody = {
        id: id,
        bodyData: {status: statusToUpdated}
      }
      await userUpdateStatus(dataBody);
      toast.success("Status has been updated successfully!");
      data.status = statusToUpdated;
    }finally {
      setIsStatusUpdating(false)
    }
  }

  const modalToggleHandler = () => {
    setIsModalActive(!isModalActive)
  }

  return (
    <>
      {
      loading && !hasData(data) ? (
        <LoaderFallbackUI
        isLoader = {loading} 
          />
      ) : null
      }
      {
      !loading && hasData(data) ? (
        <>
        <HeadingWithBackArrow
        backArrowClickHandler={backButtonHandler}
        headingTitle={typography.CUSTOMER_DETAILS}
        toggleButtonConfig= {toggleConfig}
        toggleValue= {data?.status !== typography.INACTIVE_CAPS}
        isDisabled = {isStatusUpdating}
        toggleHandler={ modalToggleHandler}
        />
        <div className="profile-bio-con">
          <div  className="profile-img-con mb-4 p-3">
            <div className="profile-img" > 
              <img
                  src={
                    data?.profilePicture?.path ||
                    APP_CONFIG.IMAGES.AVATAR_PLACEHOLDER
                }
                  alt={"profile"}
              />
            </div>
          </div>  
          <Row className="profile-bio-typo p-3">
            {userBioData.length && userBioData.map( el => 
              <Col key={el.id} xs="6" xxl="3" xl="4" md="4" sm="6" className="mb-5 " >
                  <h6 className="standard-typo-14-400">{el.title}</h6>
                  <p className={`${el.id === 4 ? Plans.getLabelClass(el.value): ""} standard-typo-16-600 `}>{el.id === 4 ? Plans.getDisplayTextKey(el.value): el.id === 3 ? GenderValuesWithSpaces[el.value] :el.value || "-"}</p>
              </Col>                  
            )}
          </Row>
        </div>
        </>
      ) : null
      }
      <ConfirmationModal
          isOpen={isModalActive}
          hasCustomConfig
          size="md"
          onNo={() => setIsModalActive(false)}
          onYes={updateUserStatus}
          customTitle={typography.STATUS_UPDATE_CONFIRMATION_MESSAGE}
          customYesBtnText={typography.YES}
          customNoBtnText={typography.NO}
          customImageSrc={ModalSuccessImage}
        />
    </>
  )
}