import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useFormik } from "formik";
import { Col, Container, Label, Row } from "reactstrap";
import { PageTitles } from "../../constants";
import {HeadingWithBackArrow} from "../../components/HeadingWithBackArrow";
import typography from "./typography";
import { APP_ROUTES } from "../../helpers/routeHelpers";
import { PasswordInput, TextInput } from "../../components/Input";
import {
  finalizeMediaUtil,
  getMediaPath,
  initializeMediaUtil,
  uploadOnS3,
} from "utils/mediaUtils"
import { initialValues, validationSchema } from "./FormikConfig";
import FormikErrorText from "../../components/FormikErrorText/FormikErrorText";
import APP_CONFIG from "../../APP_CONFIG";
import { ImageBox } from "../../components/ImageBox";
import { ConfirmationModal } from "../../components/CustomModal";
import ModalSuccessImage from '../../assets/images/app/common/aou-modal-success-img.svg'
import toast from "react-hot-toast";
import { MediaUpload, updateUserProfile } from "../../api/api.service";
import { getMe } from "../../store/actions";

export default function EditProfile() {
  const history = useHistory()
  const dispatch = useDispatch();

  const [imageFile, setImageFile] = useState("");
  const [updatedConfirmation, setUpdateConfirmation] = useState(false)
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const { name, email, profilePicture, id } = useSelector((store) => store?.userAuth?.user);
  initialValues.name = name;
  initialValues.email = email;
  const backButtonHandler = () => {
    history.push(APP_ROUTES.BLS_FEEDBACK)
  }

  
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      setUpdateConfirmation(true)
    }
  })
  
  const updateButtonHandler = () => {
    handleFormSubmit(formik.values)
  }
  const handleFormSubmit = async (values) => { 
    setUpdateConfirmation(false);
    setIsFormSubmitting(true);
    let imageId;
    if (imageFile !== "") {
        try {
            // const formData = new FormData();
            // formData.append("file", imageFile);
            const { id } = await handleMediaUpload(imageFile,true,"IMAGE");
            imageId = id * 1;
        } catch (err) {
            setIsFormSubmitting(false);
            return;
        }
    }
    const { email, name } = values;
    const bodyData = {
        email,
        name
    };
    bodyData.profilePictureId = imageId;
    try {
      let res = await updateUserProfile({id, bodyData});
      await dispatch(getMe());
      toast.success("Profile has been updated successfully.");

    }finally {
      setIsFormSubmitting(false);
    }
  };

  const handleMediaUpload = async (file, isPublic, imageType) => {
    return initializeMediaUtil(file, isPublic, imageType).then(async res => {
      const credentials = res
      await uploadOnS3(file, credentials)
      return await finalizeMediaUtil(credentials?.mediaId, true);
    }) 
  }

  return(
    <>
      <div className="page-content">
        <Helmet>
            <title>{PageTitles.EDIT_PROFILE}</title>
        </Helmet>
        <Container fluid className="edit-profile">
          <form className="mt-5" onSubmit={formik.handleSubmit}>
          <HeadingWithBackArrow
            backArrowClickHandler={backButtonHandler}
            headingTitle={typography.EDIT_PROFILE}
            buttonTitle={typography.UPDATE}
            buttonHandler ={() => setImageFile(true)}
            isButtonLoading={isFormSubmitting}
           />
           <div className="edit-profile-content p-5 ">
            <Row>
              <Col xs="12" md="6" lg="6" xl="4">
              <div className=" mb-4">
                <ImageBox
                    containerClasses="w-100 h-100"
                    isEditable={true}
                    imageClasses="rounded-circle profile-image"
                    editClasses="profile-edit-icon"
                    onImageUpload={setImageFile}
                    placeholderImg={APP_CONFIG.IMAGES.AVATAR_PLACEHOLDER}
                    imageToPreview={profilePicture?.path}
                    isRemoveable={true}
                />
              </div>
              </Col>
              <Col xs="12" md="12" lg="12" xl="12" className="mt-3">
                <div className="change-pass-input-con">
                  <Label>{typography.NAME}</Label>
                  <div className="mb-4">
                      <TextInput
                          placeholder={typography.ENTER_NAME}
                          name={"name"}
                          type="text"
                          className="hide-default-icon"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.name}
                          // autoFocus
                      />
                      <FormikErrorText
                      formikInstance={formik} 
                      fieldName={"name"} />
                  </div>
                </div>
              </Col>
              <Col xs="12" md="12" lg="12" xl="12" className="mt-3">
                <div className="change-pass-input-con">
                  <Label>{typography.EMAIL}</Label>
                  <div className="mb-4">
                      <TextInput
                          placeholder={typography.ENTER_EMAIL}
                          name={"email"}
                          type="email"
                          className="hide-default-icon"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                          // autoFocus
                      />
                      <FormikErrorText 
                      formikInstance={formik} 
                      fieldName={"email"} />
                  </div>
                </div>
              </Col>
              
            </Row>
           </div>
            </form>
        </Container>
        <ConfirmationModal
          isOpen={updatedConfirmation}
          hasCustomConfig
          size="md"
          onNo={() => setUpdateConfirmation(false)}
          onYes={() => updateButtonHandler()}
          customTitle="Are you sure you want to update your profile?"
          customYesBtnText="Yes"
          customNoBtnText="No"
          customImageSrc={ModalSuccessImage}
      />
      </div>
    </>
  )
}