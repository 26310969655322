import { ME_SUCCESS, SET_TOKEN } from "../actions";

const initialState = {
    isAuthenticated: false,
    user: {}
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ME_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                isLoading: false,
                user: {
                    ...state.user,
                    ...action.payload
                }
            };
        case SET_TOKEN:
            return {
                ...state,
                user: {
                    ...state.user,
                    Token: action.token
                }
            };

        default:
            return state;
    }
};

export default reducer;
