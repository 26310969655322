import React from "react";
import { Button } from "reactstrap";

import { Loader } from "components/Loader";
import "./CustomButton.scss";

const CustomButton = (props) => {
    const {
        className = "",
        loaderColor = "light",
        title,
        outline = false,
        loading = false,
        color,
        onClick,
        type,
        disabled,
        name
    } = props;
    return (
        <Button
            name={name}
            disabled={disabled || loading}
            type={type}
            color={color}
            onClick={onClick}
            outline={outline}
            className={`custom-btn ${className} ${outline && "btn-outline-primary"}`}
        >
            {loading ? <Loader size={"sm"} color={loaderColor} /> : title}
        </Button>
    );
};

export default CustomButton;
