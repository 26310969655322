import { Helmet } from "react-helmet";
import { Container } from "reactstrap";

import { PageTitles } from "../../../constants";

import Biography from "./Biography";
import Affirmations from "./Affirmations";

export default function Details () {

  return(
    <>
      <div className="page-content">
        <Helmet>
            <title>{PageTitles.CUSTOMER_DETAILS}</title>
        </Helmet>
        <Container fluid className="customer-details">          
           <Biography />
           <Affirmations />
        </Container>
        
      </div>
    </>
  )
}