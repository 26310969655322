const typography = {
EDIT_PROFILE: "Edit Profile",
UPDATE: "Update",
NAME: "Name",
EMAIL: "Email",
CURRENT_PASSWORD: "Current Password",
NEw_PASSWORD: "New Password",
REENTER_PASSWORD: "Re Enter Password",
ENTER_EMAIL: "Enter Email",
ENTER_NAME: "Enter Name",
ENTER_PASSWORD: "Enter Password",
ENTER_NEW_PASSWORD: "Enter New Password",
ENTER_REENTER_PASSWORD: "Enter New Password Again",
CHANGE_PASSWORD: "Change Password",
ENTER_EMAIL_ADDRESS: "Enter email address",
ENTER_VALID_EMAIL_ADDRESS: "Enter a valid email address",
TOO_SHORT_EMAIL: "Too short email!",
TOO_LONG_EMAIL: "Too long email!",
PASSWORD_MIN_LENGTH: "Password should be minimum 6 characters long",
PASSWORD_MAX_LENGTH: "Password should be between 6 to 25 characters",
PASSWORD_CHARACTERS_VALIDATION: "Password must contains characters, special characters and numbers",
PASSWORD_MATCH: "Please make sure your passwords match",
PASSWORD_SHOULD_BE_DIFFERENT: "New password should be different from old password",
ENTER_CONFIRM_PASSWORD: "Please enter your confirm password",
ENTER_YOUR_NAME: "Please Enter Your Name",
NAME_IS_TOO_SHORT: "Name is too short!",
NAME_IS_TOO_LONG: "Name is too long!",
PASSWORD_UPDATED_SUCCESSFULLY: "Password has been updated successfully.",
UPDATE_PROFILE_ASSURANCE: "Are you sure you want to update your profile?",
UPDATE_PASSWORD_ASSURANCE: "Are you sure you want to update your password?",
YES: "Yes",
NO: "No"
}

export default typography