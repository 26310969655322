import { combineReducers } from "redux";
import { SIGN_OUT } from "../actions";
import Layout from "../layout/reducer";
import authReducer from "./authReducer";
import root from "./rootReducer";

const appReducer = combineReducers({
    root,
    Layout,
    userAuth: authReducer
});

const rootReducer = (state, action) => {
    if (action.type === SIGN_OUT) {
        const { Layout, deviceId } = state;
        state = { Layout, deviceId };
    }

    return appReducer(state, action);
};

export default rootReducer;
