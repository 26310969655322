import { all, fork } from "redux-saga/effects";
import LayoutSaga from "./layout/saga";
// import dashboardSaga from "./dashboard/saga"
// import dashboardSaasSaga from "./dashboard-saas/saga"
//public
// import AccountSaga from "./auth/register/saga"
// import AuthSaga from "./auth/login/saga"
// import ForgetSaga from "./auth/forgetpwd/saga"
// import ProfileSaga from "./auth/profile/saga"
// import ecommerceSaga from "./e-commerce/saga"
// import calendarSaga from "./calendar/saga"
// import chatSaga from "./chat/saga"
// import cryptoSaga from "./crypto/saga"
// import invoiceSaga from "./invoices/saga"
// import projectsSaga from "./projects/saga"
// import tasksSaga from "./tasks/saga"
// import mailsSaga from "./mails/saga"
// import contactsSaga from "./contacts/saga";

export default function* rootSaga() {
    yield all([
        fork(LayoutSaga)
        // fork(dashboardSaga),
        // fork(dashboardSaasSaga),

        //public
        // fork(AccountSaga),
        // fork(AuthSaga),
        // fork(ForgetSaga),
        // fork(ProfileSaga),
        // fork(ecommerceSaga),
        // fork(calendarSaga),
        // fork(chatSaga),
        // fork(mailsSaga),
        // fork(cryptoSaga),
        // fork(invoiceSaga),
        // fork(projectsSaga),
        // fork(tasksSaga),
        // fork(contactsSaga),
    ]);
}
