import React from "react";

import CustomModal from "./CustomModal";
import { CustomButton } from "../CustomButton";
import { CONFIRMATION_MODAL_CONFIG, getConfirmationModalConfig } from "./ConfirmationModalConfig";

const ConfirmationModal = ({
    isOpen = false,
    modalType = "DELETE",
    hasCustomConfig = false,
    customTitle = "",
    customYesBtnText = "",
    customNoBtnText = "",
    customImageSrc = "",
    onYes = () => {},
    onNo = () => {},
    heading= "",
    ...rest
}) => {
    let customConfigObj = hasCustomConfig
        ? {
              customTitle,
              customYesBtnText,
              customNoBtnText,
              customImageSrc
          }
        : null;

    let Config = getConfirmationModalConfig(CONFIRMATION_MODAL_CONFIG, modalType, customConfigObj);
    return (
        <div>
            <CustomModal isOpen={isOpen} headerClass="d-none" modalBodyClasses="py-5" {...rest}> 
                <div className="d-flex flex-column align-items-center px-2 px-xs-5">
                    <img src={Config.ImageSrc} className="mb-3 " />
                    {heading !== "" ? (<><p className="standard-heading-22-600 mb-4">{heading}</p></>) : null}
                    <div className=" mb-4  ">
                        <h4 className="standard-typo-16-500-ld text-center px-3">{Config.Title}</h4>
                        {Config.SubText && (
                            <p className="my-3 font-clr-theme-secondary">{Config.SubText}</p>
                        )}
                    </div>

                    <div className="d-flex  w-100 modal-buttons">
                        <CustomButton
                            title={Config.YesButtonText}
                            color="primary"
                            className="custom-btn-sm shadow-none fw-bold"
                            onClick={onYes}
                        />
                        <CustomButton
                            title={Config.NoButtonText}
                            className="custom-btn-sm fw-bold shadow-none second-button"
                            color="primary"
                            outline
                            onClick={onNo}
                        />
                    </div>
                </div>
            </CustomModal>
        </div>
    );
};

export default ConfirmationModal;
