import { BLSFeedback } from "../pages/BLSFeedback"
import { CustomerManagement, CustomerDetails } from "../pages/CustomerManagement";

import { APP_ROUTES } from "../helpers/routeHelpers";
import { Roles } from "../constants";
import { EditProfile, ChangePassword } from "../pages/Account"; 

let { Admin } = Roles;

const authProtectedRoutes = [
    // BLSFeedback
    {
        path: APP_ROUTES.BLS_FEEDBACK,
        component: BLSFeedback,
        permissions: [Admin],
        exact: true
    },
    // Customer Management
    {
        path: APP_ROUTES.CUSTOMER_MANAGEMENT,
        component: CustomerManagement,
        permissions: [Admin],
        exact: true
    },
    {
        path: `${APP_ROUTES.CUSTOMER_DETAILS}/:id`,
        component: CustomerDetails,
        permissions: [Admin],
        exact: false
    },
     {
        path: APP_ROUTES.EDIT_PROFILE,
        component: EditProfile,
        permissions: [Admin],
        exact: true
    },
    {
       path: APP_ROUTES.CHANGE_PASSWORD,
       component: ChangePassword,
       permissions: [Admin],
       exact: true
   }
];

export { authProtectedRoutes };
