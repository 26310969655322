import PropTypes from "prop-types"
import React, { useState } from "react"
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap"
import DateRangePicker from "react-bootstrap-daterangepicker"

import "./DataTable.scss"
import "bootstrap-daterangepicker/daterangepicker.css"
import { CustomButton } from "../../components/CustomButton"
import { dateTimeFormat } from "../../utils/dateFormatUtils"
import { FilterTypes } from "../../constants"

const CustomFilter = props => {
  const { options } = props
  const { title, filterValue, filterConstants, onFilterSelect } = options
  const [open, setOpen] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  const handleMenuToggle = e => {
    let dropdownEl = document.getElementsByClassName("daterangepicker")[0]

    if (open && dropdownEl && dropdownEl.contains(e.target)) {
      return
    }
    setOpen(prevOpen => !prevOpen)
  }

  const handleItemSelect = value => {
    onFilterSelect(value)
  }

  const handleDateSelect = (event, picker) => {
    const { startDate, endDate } = picker

    let startDateFilter = startDate.format(dateTimeFormat.apiDateFormat),
      endDateFilter = endDate.format(dateTimeFormat.apiDateFormat)
    setStartDate(startDate)
    setEndDate(endDate)
    handleItemSelect(`${startDateFilter}|${endDateFilter}`)
  }

  const handleCancelDate = () => {
    setStartDate(null)
    setEndDate(null)
    handleItemSelect(null)
  }

  return filterConstants === FilterTypes.SEARCH_FILTER ? (
    <Dropdown isOpen={open} toggle={handleMenuToggle}>
      <DropdownToggle tag="div" className=" data-table-head sortable">
        {title}
        {filterValue && (
          <span className="filter-value" title={`(${filterValue})`}>
            ({filterValue?.slice?.(0, 8)}
            {filterValue?.length > 8 ? "..." : ""})
          </span>
        )}
        <i className="mdi mdi-chevron-down"></i>
      </DropdownToggle>
      <DropdownMenu className="custom-filter-body">
        <div className="px-2">
          <div className="form-group row">
            <div className="col-md-12">
              <input
                className="form-control"
                type="text"
                placeholder="search"
                value={searchText}
                onChange={e => setSearchText(e.target.value)}
              />
            </div>
          </div>
          <CustomButton
            color="success"
            className="mr-2"
            size="sm"
            onClick={() => {
              handleItemSelect(searchText)
              setOpen(false)
            }}
          >
            Apply
          </CustomButton>
          <CustomButton
            color="danger"
            size="sm"
            onClick={() => {
              setSearchText("")
              handleItemSelect("")
              setOpen(false)
            }}
          >
            Clear
          </CustomButton>
        </div>
      </DropdownMenu>
    </Dropdown>
  ) : (
    <DateRangePicker
      startDate={startDate}
      endDate={endDate}
      locale={{ format: dateTimeFormat.apiDateFormat }}
      onApply={handleDateSelect}
      onCancel={handleCancelDate}
    >
      <div className="cursor-pointer">
        {title}
        {filterValue && (
          <span className="filter-value" title={`(${filterValue})`}>
            ({filterValue})
          </span>
        )}
        <i className="mdi mdi-chevron-down"></i>
      </div>
    </DateRangePicker>
  )
}

CustomFilter.propTypes = {
  options: PropTypes.any,
}

export default CustomFilter
