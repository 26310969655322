import { injectBaseConstantMethods } from "./BaseConstants";

const UserStatus = {
    Unverified: 0,
    Verified: 1,
    Active: 2,
    Pending: 3,
    Rejected: 4,
    Blocked: 5
};

const displayTextKeys = {
    [UserStatus.Unverified]: "Unverified",
    [UserStatus.Verified]: "Verified",
    [UserStatus.Active]: "Active",
    [UserStatus.Pending]: "Pending",
    [UserStatus.Rejected]: "Rejected",
    [UserStatus.Blocked]: "Blocked"
};

const labelClass = {
    [UserStatus.Unverified]: "Unverified",
    [UserStatus.Verified]: "Verified",
    [UserStatus.Active]: "cs-badge cs-badge-active",
    [UserStatus.Pending]: "cs-badge cs-badge-pending",
    [UserStatus.Rejected]: "cs-badge cs-badge-rejected",
    [UserStatus.Blocked]: "cs-badge cs-badge-blocked"
};

export default injectBaseConstantMethods(UserStatus, displayTextKeys, labelClass);
