import * as Yup from "yup";
import typography from "./typography";

export const initialValues= {
  name: "",
  email: "",
}

export const validationSchema = Yup.object({
  name: Yup.string().required(typography.ENTER_YOUR_NAME)
  .min(3, typography.NAME_IS_TOO_SHORT)
  .max(50, typography.NAME_IS_TOO_LONG),
  email: Yup.string()
      .email(typography.ENTER_VALID_EMAIL_ADDRESS)
      .required(typography.ENTER_EMAIL_ADDRESS)
      .min(6, typography.TOO_SHORT_EMAIL)
      .max(50, typography.TOO_LONG_EMAIL)
      .matches(
          /([A-Z|a-z|0-9](\.|_){0,1})+[A-Z|a-z|0-9]\@([A-Z|a-z|0-9])+((\.){0,1}[A-Z|a-z|0-9]){2}\.[a-z]{2,3}$/,
          typography.ENTER_VALID_EMAIL_ADDRESS
      ),
})

export const initialValuesPassChange= {
    password: "",
    newPassword: "",
    confirmPassword: ""
  } 

  export const validationSchemaPassChange = Yup.object({
    password: Yup.string().required(typography.ENTER_PASSWORD),
    newPassword: Yup.string()
    .required(typography.ENTER_NEW_PASSWORD)
    .min(6, typography.PASSWORD_MIN_LENGTH)
    .max(25, typography.PASSWORD_MAX_LENGTH)
    .notOneOf(
        [Yup.ref("password")],
        typography.PASSWORD_SHOULD_BE_DIFFERENT
        )
    .test(
        "regex",
        typography.PASSWORD_CHARACTERS_VALIDATION,
        (val) => {
            let regExp = new RegExp(
                "^(?=.*\\d)(?=.*[!@#$%^&*])(?=.*[a-z])|(?=.*[A-Z]).{8,}$"
            );
            return regExp.test(val);
        }
    ),
    confirmPassword: Yup.string()
    .oneOf(
        [Yup.ref("newPassword")],
        typography.PASSWORD_MATCH
        )
        .required(typography.ENTER_CONFIRM_PASSWORD)
  })