import React from "react";
import { Input } from "reactstrap";

const TextInput = ({
    className = "hide-default-icon", //pass class hide-icon to remove icon css
    containerClasses = "",
    inputClasses = "",
    iconClasses="",
    name,
    placeholder,
    type = "text",
    onChange,
    value,
    children,
    autoComplete = "off",
    icon = null,
    label = "",
    size = "",
    isPassword= false,
    onIconClick= () => {},
    ...rest
}) => {
    return (
        <div className={`d-flex flex-column ${containerClasses}`}>
            {label && <p className="input-label fw-bold mb-2">{label}</p>}

            <div className={`custom-text-input ${icon && "hide-default-icon"} ${className} `}>
                <Input
                    {...rest}
                    bsSize={size}
                    name={name}
                    className={`form-control font-family-source-sans-400 ${inputClasses}`}
                    placeholder={placeholder}
                    type={type}
                    onChange={onChange}
                    value={value}
                    autoComplete={autoComplete}
                >
                    {children}
                </Input>
                {icon && <div className={`input-icon cursor-pointer ${iconClasses   }`} onClick={onIconClick}>
                    {isPassword ? icon : (<img src={icon} alt="input icon" />)}
                </div>}
                {children}
            </div>
        </div>
    );
};

export default TextInput;
