
const typography = {
  ACTIVE: "Active",
  INACTIVE: "Inactive",
  ACTIVE_CAPS: "ACTIVE",
  INACTIVE_CAPS: "INACTIVE",
  ALL: "All",
  FREE: "Free",
  PREMIUM: "Premium",
  NAME: "Name",
  EMAIL: "Email",
  PHONE: "Phone",
  PLAN: "Plan",
  AGE: "Age",
  DATE_OF_BIRTH: "Date of Birth",
  STATUS: "Status",
  ACTION: "Action",
  OCCUPATION: "Occupation",
  GENDER: "Gender",
  MEMBERSHIP_PLAN: "Membership Plan",
  CUSTOMER_DETAILS: "Customer Details",
  AFFIRMATION_DETAILS: "Affirmation Details",
  DATE: "Date",
  CATEGORY: "Category",
  BLS_DURATION: "BLS Duration",
  FEEDBACK: "Feedback",
  STATUS_UPDATE_CONFIRMATION_MESSAGE: "Are you sure you want to update user status?",
  YES: "Yes",
  NO: "No",
  CUSTOMER: "CUSTOMER",
  USER: "USER"
}

export default typography