import loginLeftSectionBgImg from "assets/images/app/auth-page/login-left-bg.svg";
import sideBarLogoImg from "assets/images/app/common/aou-logo-white.svg";
import logo from "assets/images/app/common/pured-logo.svg";
import placeholderAvatar from "./assets/images/app/common/user-placeholder.png";
import aouLogoImg from "assets/images/app/common/aou-logo-white.svg";

const APP_CONFIG = {
    APP_NAME: "Age of Uncertainity",
    AUTH_LEFT_TEXT: "",
    LOGIN_LEFT_MAIN_TEXT: "",
    LOGIN_LEFT_SUB_TEXT: "",
    OPEN_SIDEBAR_APP_LOGO_SIZE: { width: "235", height: "35" },
    COLLAPSED_SIDEBAR_APP_LOGO: { width: "45", height: "40" },

    IMAGES: {
        APP_LOGO: logo,
        OPEN_SIDEBAR_APP_LOGO: sideBarLogoImg,
        COLLAPSED_SIDEBAR_APP_LOGO: sideBarLogoImg,
        LOGIN_LEFT_BG_IMAGE: loginLeftSectionBgImg,
        AUTH_LEFT_OVERLAY_IMAGE: "",
        LOGIN_RIGHT_IMAGE: "",
        LOGIN_LEFT_MAIN_IMAGE: logo,
        ICON_PLACEHOLDER: "https://icons8.com/icon/OZzk5umGngVd/icon",
        AVATAR_PLACEHOLDER: placeholderAvatar,
        AOU_LOGO: aouLogoImg
        // AVATAR_PLACEHOLDER: placeholderAvatar
    },

    IS_FOOTER: false,
    FOOTER_CONTENT: ""
};

export default APP_CONFIG;
