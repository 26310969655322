import typography from "../typography"

export const profileDetailsConfig = [
  {
    id: 0,
    date: "Sep 06, 2023",
    category: "Responsibility",
    BLSDuration: "90 Sec",
    feedback: "Submitted" 
  },
  {
    id: 1,
    date: "Sep 15, 2023",
    category: "Action",
    BLSDuration: "90 Sec",
    feedback: "Not Submitted" 
  },
  {
    id: 2,
    date: "Sep 19, 2023",
    category: "Power",
    BLSDuration: "90 Sec",
    feedback: "Submitted" 
  },
  {
    id: 3,
    date: "Sep 22, 2023",
    category: "Power",
    BLSDuration: "90 Sec",
    feedback: "Not Submitted" 
  },
  {
    id: 4,
    date: "Sep 29, 2023",
    category: "Responsibility",
    BLSDuration: "90 Sec",
    feedback: "Submitted" 
  },
  {
    id: 5,
    date: "Oct 08, 2023",
    category: "Action",
    BLSDuration: "90 Sec",
    feedback: "Not Submitted" 
  },
  {
    id: 6,
    date: "Oct 16, 2023",
    category: "Responsibility",
    BLSDuration: "90 Sec",
    feedback: "Submitted" 
  }
]

export 
const profileBioConfig = [
  {
    id: 0,
    title: typography.NAME,
    key: "name",
    value: ""
  },
  {
    id: 1,
    title: typography.EMAIL,
    key: "email",
    value: ""
  },
  {
    id: 2,
    title: typography.OCCUPATION,
    key: "occupation",
    value: ""
  },
  {
    id: 3,
    title: typography.GENDER,
    key: "gender",
    value: ""
  },
  {
    id: 4,
    title: typography.MEMBERSHIP_PLAN,
    key: "subscriptionTypes",
    value: ""
  },
  {
    id: 5,
    title: typography.PHONE,
    key: "phone",
    value: ""
  },
  {
    id: 6,
    title: typography.DATE_OF_BIRTH,
    key: "dateOfBirth",
    value: ""
  },
  {
    id: 7,
    title: typography.AGE,
    key: "age",
    value: ""
  }
]