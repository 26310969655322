export default {
    LOGIN: "Login",
    FORGET_PASSWORD: "Forget Password",
    OTP_VERIFICATION: "OTP Verification",
    LOGOUT: "Logout",
    REGISTER: "Register",
    RESET_PASSWORD: "Reset Password",
    BLS_FEEDBACK: "BLS Feedback",
    CUSTOMER_MANAGEMENT: "Customer Management",
    CUSTOMER_DETAILS: "Customer Details",
    EDIT_PROFILE: "Edit Profile",
    CHANGE_PASSWORD: "Change Password",
};
