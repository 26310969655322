import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { withRouter, useHistory, useParams, Redirect, Link } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Label } from "reactstrap";

import AuthWrapper from "./AuthWrapper";
import { PasswordInput } from "components/Input";
import { CustomButton } from "components/CustomButton";
import { APP_ROUTES, getParamsAttachedRoute } from "helpers/routeHelpers";
import { ResetPassword } from "api/api.service";
import { PageTitles } from "../../constants";
import Headings from "./Headings";
import { CustomModal } from "../../components/CustomModal";
import successImg from "../../assets/images/app/common/modal-success-img.svg";
import { successMessages, validationMessages } from "./SuccessErrorMessages";
import typography from "./typography.js";
 
const ResetPasswordView = (props) => {
    let {} = props;

    const history = useHistory();
    const { code, tokenId } = useParams();
    let isAuthenticated = useSelector((state) => state.userAuth.isAuthenticated);

    const [isLoading, setIsLoading] = useState(false);
    const [showResetSuccessModal, setShowResetSuccessModal] = useState(false);

    const formik = useFormik({
        initialValues: {
            newPassword: "",
            confirmPassword: ""
        },
        validationSchema: Yup.object({
            newPassword: Yup.string()
            // Alpha
            .required(validationMessages.newPasswordRequired)
            .min(6, validationMessages.passwordMinLength)
            .max(25, validationMessages.passwordMaxLength)
            .test(
                "regex",
                typography.PASSWORD_CHARACTERS_VALIDATION,
                (val) => {
                    let regExp = new RegExp(
                        "^(?=.*\\d)(?=.*[!@#$%^&*])(?=.*[a-z])|(?=.*[A-Z]).{8,}$"
                    );

                    return regExp.test(val);
                }
            ),
            confirmPassword: Yup.string()
            .oneOf(
                [Yup.ref("newPassword")],
                validationMessages.passwordMatch
            )
            // Alpha
            .required(validationMessages.confirmPassword)
        }),

        onSubmit: (values) => {
            handleSubmit(values);
        }
    });

    const handleSubmit = async (values) => {
        let { confirmPassword } = values;

        try {
            setIsLoading(true);
            let body = {
                token: tokenId,
                password: confirmPassword
            };
            let res = await ResetPassword(body);
            toast.success(successMessages.passwordReset);
            history.push(getParamsAttachedRoute(APP_ROUTES.LOGIN));
        } finally {
            setIsLoading(false);
        }
    };

    if (isAuthenticated) {
        return <Redirect to={APP_ROUTES.BLS_FEEDBACK} />;
    }
    return (
        <>
            <Helmet>
                <title>{PageTitles.RESET_PASSWORD}</title>
            </Helmet>
            <AuthWrapper>
                <div className="bv-forget-wrap">
                    <Headings title={typography.PASSWORD_RESET}subText={typography.SET_YOUR_NEW_PASSWORD} />
                    <form className="mt-5" onSubmit={formik.handleSubmit}>
                        <Label>{typography.NEW_PASSWORD}</Label>
                        <div className="mb-4">
                            <PasswordInput
                                placeholder={typography.ENTER_PASSWORD}
                                type="password"
                                name="newPassword"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.newPassword}
                            />
                            {formik.touched.newPassword && formik.errors.newPassword ? (
                                <span className="error">{formik.errors.newPassword}</span>
                            ) : null}
                        </div>

                        <Label>{typography.CONFIRM_PASSWORD}</Label>
                        <div className="mb-4">
                            <PasswordInput
                                placeholder={typography.ENTER_PASSWORD_AGAIN}
                                type="password"
                                name="confirmPassword"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.confirmPassword}
                            />
                            {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                                <span className="error">{formik.errors.confirmPassword}</span>
                            ) : null}
                        </div>

                        <CustomButton
                            loading={isLoading}
                            color="primary"
                            type={"submit"}
                            title={typography.RESET_PASSWORD}
                            className="w-100"
                        />
                        <div>
                            <Link
                                className="d-flex align-items-center justify-content-center my-4"
                                to={APP_ROUTES.LOGIN}
                            >
                                <p className="standard-typo-14-600"> {typography.BACK_TO_LOGIN} </p>
                            </Link>
                        </div>
                    </form>
                </div>
                <CustomModal isOpen={showResetSuccessModal} size="md" modalBodyClasses="px-5">
                    <div className="text-center px-5">
                        <img src={successImg} className="mb-4" />
                        <h4 className="font-clr-theme-secondary font-family-lexend">
                        {typography.PASSWORD_RESET_SUCCESSFULLY}
                        </h4>
                        <p className="my-4 font-family-lexend fw-500 font-size-14">
                        {typography.YOUR_PASSWORD_HAS_BEEN_RESET}
                        </p>
                        <CustomButton
                            color="primary"
                            title={typography.BACK_TO_LOGIN}
                            className="custom-btn-sm w-100 mb-5"
                            onClick={() => history.push(getParamsAttachedRoute(APP_ROUTES.LOGIN))}
                        />
                    </div>
                </CustomModal>
            </AuthWrapper>
        </>
    );
};

export default withRouter(ResetPasswordView);
