import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Label } from "reactstrap";
import { CustomModal } from "../../components/CustomModal";
import { useSelector } from "react-redux";
import { withRouter, Redirect, useHistory, Link } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";

import AuthWrapper from "./AuthWrapper";
import { TextInput } from "../../components/Input";
import { CustomButton } from "../../components/CustomButton";
import { APP_ROUTES, getParamsAttachedRoute } from "../../helpers/routeHelpers";
import { ForgotPassword } from "api/api.service";
import { PageTitles } from "../../constants";
import Headings from "./Headings";
import toast from "react-hot-toast";
import { successMessages, validationMessages } from "./SuccessErrorMessages";
import typography from './typography';

const ForgetPassword = ({}) => {
    const history = useHistory();
    let isAuthenticated = useSelector((state) => state.userAuth.isAuthenticated);

    const [isLoading, setIsLoading] = useState(false);
    // Alpha --------------
    const [holdToken, setHoldToken] = useState("");
    const [holdEmail, setHoldEmail] = useState("");
    const [showOtpModal, setShowOtpModal] = useState(false);

    const formik = useFormik({
        // enableReinitialize: true,
        initialValues: {
            email: ""
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email(validationMessages.emailValidation)
                .required(validationMessages.emailRequired)
                .min(6, validationMessages.shortEmail)
                .max(50, validationMessages.longEmail)
                .matches(
                    /([A-Z|a-z|0-9](\.|_){0,1})+[A-Z|a-z|0-9]\@([A-Z|a-z|0-9])+((\.){0,1}[A-Z|a-z|0-9]){2}\.[a-z]{2,3}$/,
                    validationMessages.emailValidation
                )
        }),
        onSubmit: (values) => {
            handleEmailSubmit(values);
        }
    });

    const handleEmailSubmit = async (values) => {
        const { email } = values;
        try {
            setIsLoading(true);
            let res = await ForgotPassword({ email, type: typography.TYPE_ADMIN });
            toast.success(successMessages.OTPSent);
            setHoldEmail(email);
            setHoldToken(res?.token);
            setShowOtpModal(true);
        } finally {
            setIsLoading(false);
        }
    };

    if (isAuthenticated) {
        return <Redirect to={APP_ROUTES.BLS_FEEDBACK} />;
    }

    return (
        <>
            <Helmet>
                <title>{PageTitles.FORGET_PASSWORD}</title>
            </Helmet>
            <AuthWrapper
            >
                <div className="bv-forget-wrap">
                    <Headings title={typography.FORGOT_PASSWORD} subText={typography.RESET_PASSWORD} />

                    <form className="mt-5" onSubmit={formik.handleSubmit}>
                        <p className="mb-4  text-left p-0 standard-typo-14-400">
                        {typography.ENTER_EMAIL_RECEIVE_OTP}
                        </p>
                        <Label>{typography.EMAIL}</Label>
 
                        <div className="mb-4">
                            <TextInput
                                placeholder={typography.ENTER_EMAIL}
                                name="email"
                                type="email"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                                className="hide-default-icon"
                            />
                            {formik.touched.email && formik.errors.email ? (
                                <span className="error">{formik.errors.email}</span>
                            ) : null}
                        </div>

                        <CustomButton
                            loading={isLoading}
                            color="primary"
                            title={typography.SEND_OTP}
                            className="w-100"
                        />
                        <div>
                            <Link
                                className="d-flex align-items-center justify-content-center my-4"
                                to={APP_ROUTES.LOGIN}
                            >
                                <p className="standard-typo-14-600 "> {typography.BACK_TO_LOGIN} </p>
                            </Link>
                        </div>
                    </form>
                </div>
            </AuthWrapper>
            <CustomModal isOpen={showOtpModal} size="md">
                <div className="text-center">
                    <h2 className="font-clr-theme-secondary font-family-lexend">{typography.OTP_SENT}</h2>
                    <p className="my-4 font-family-lexend fw-500 font-size-14">
                        {typography.ONE_TIME_OTP_SENT} <br /> {typography.EMAIL_ADDRESS}
                    </p>
                    <CustomButton
                        color="primary"
                        title={typography.CONTINUE}
                        className="custom-btn-sm mb-5"
                        onClick={() =>
                            history.push({
                                pathname: getParamsAttachedRoute(APP_ROUTES.FORGET_VERIFY_CODE, {
                                    tokenId: holdToken
                                }),
                                state: { email: holdEmail }
                            })
                        }
                    />
                </div>
            </CustomModal>
        </>
    );
};

export default withRouter(ForgetPassword);
