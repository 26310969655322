const typography = {
  TYPE_ADMIN: "ADMIN",
  FORGOT_PASSWORD: "Forgot Password?",
  RESET_PASSWORD: "Reset Your Password",
  ENTER_EMAIL_RECEIVE_OTP: "Enter your email to receive One time password (OTP)",
  EMAIL: "Email",
  ENTER_EMAIL: "Enter Email",
  SEND_OTP: "Send OTP",
  BACK_TO_LOGIN: "Back to Login",
  OTP_SENT: "OTP Sent",
  ONE_TIME_OTP_SENT: "One Time Password has been sent to your provided",
  EMAIL_ADDRESS: "email address.",
  CONTINUE: "Continue",
  OTP_VERIFICATION: "OTP Verification",
  ENTER_ONE_TIME_PASSWORD_OTP: "Please enter One Time Password (OTP) sent to your email",
  ENTER_OTP: "Enter OTP",
  SUBMIT: "Submit",
  DID_NOT_RECEIVE: "Didn’t Receive Yet?",
  SENDING: "Sending...",
  RESEND_NOW: "Resend Now",
  OTP_VERIFIED_SUCCESSFULLY: "OTP Verified Successfully",
  ONE_TIME_OTP_VERIFIED_SUCCESSFULLY: "One Time Password has been verified successfully.",
  CONTINUE_TO_RESET_PASSWORD: "Continue to reset your password",
  PASSWORD_RESET: "Reset Password",
  SET_YOUR_NEW_PASSWORD: "Set Your New Password",
  NEW_PASSWORD: "New Password",
  ENTER_PASSWORD: "Enter Password",
  CONFIRM_PASSWORD: "Confirm Password",
  ENTER_PASSWORD_AGAIN: "Enter Password Again",
  PASSWORD_RESET_SUCCESSFULLY: "Password Reset Successfully",
  YOUR_PASSWORD_HAS_BEEN_RESET: "Your password has been reset.",
  HEY_THERE: "Hey There,",
  WELCOME_BACK: "Welcome Back",
  PASSWORD: "Password",
  SIGN_IN: "Sign In",
  passwordValidation: "New password is invalid.",
  PASSWORD_CHARACTERS_VALIDATION: "Password must contains characters, special characters and numbers",
  
}

export default typography