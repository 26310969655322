import React from "react";
import AuthCode from "react-auth-code-input";
import "./CodeInput.scss";

const CodeInput = ({
    className,
    characters = 4,
    onChange = () => null,
    isPassword = false,
    disabled = false
}) => {
    return (
        <div>
            <AuthCode
                isPassword
                allowedCharacters="numeric"
                length={characters}
                containerClassName="text-center"
                inputClassName="otp-input"
                onChange={onChange}
            />
        </div>
    );
};

export default CodeInput;
