import React from "react";
import PropTypes from "prop-types";

const FormikErrorText = (props) => {
    let { formikInstance, fieldName } = props;

    return (
        <>
            {formikInstance.touched[fieldName] && formikInstance.errors[fieldName] ? (
                <span className="error">{formikInstance.errors[fieldName]}</span>
            ) : null}
        </>
    );
};

FormikErrorText.propTypes = {
    formikInstance: PropTypes.any,
    fieldName: PropTypes.any
};

export default FormikErrorText;
