import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { withRouter, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";

import AuthWrapper from "./AuthWrapper";
import { CustomModal } from "../../components/CustomModal";
import { CodeInput } from "../../components/CodeInput";
import { CustomButton } from "../../components/CustomButton";
import { APP_ROUTES, getParamsAttachedRoute } from "helpers/routeHelpers";
import { ForgotPasswordVerification, ResendOTP } from "api/api.service";
import { PageTitles } from "../../constants";
import Headings from "./Headings";
import { Row, Col, Label } from "reactstrap";
import typography from "./typography.js";
import { successMessages } from "./SuccessErrorMessages.js";

const OTP_LENGTH = 4;

const ForgetVerifyCode = ({}) => {
    const history = useHistory();
    const location = useLocation();
    const { tokenId } = useParams();
    let isAuthenticated = useSelector((state) => state?.userAuth?.isAuthenticated);

    const [isLoading, setIsLoading] = useState(false);
    const [OTP, setOTP] = useState("");
    const [email, setEmail] = useState("");
    const [token, setToken] = useState("");
    // Alpha
    const [codeAndToken, setCodeAndToken] = useState({ code: "0000", tokenUId: "01010101" });
    const [isResendCode, setIsResendCode] = useState(false);
    const [showOtpModal, setShowOtpModal] = useState(false);

    useEffect(() => {
        if (!tokenId) {
            return;
        }

        setToken(tokenId);
        setEmail(location?.state?.email || "");
    }, [tokenId]);

    const handleSubmit =
         async (e) => {
            e.preventDefault();
            try {
                setIsLoading(true);
                let body = {
                    code: OTP,
                    token: tokenId
                };
                let res = await ForgotPasswordVerification(body);
                let { token } = res;
                setCodeAndToken({ tokenUId: token });
                setShowOtpModal(true);
            } finally {
                setIsLoading(false);
            }
        };

    const handleResendCode = async (e) => {
        e.preventDefault();
        try {
            setIsResendCode(true);
            let res = await ResendOTP({ email,  token: tokenId});
            toast.success(successMessages.OTPReSent);
            history.push({
                pathname: getParamsAttachedRoute(APP_ROUTES.FORGET_VERIFY_CODE, {
                    tokenId: res?.token
                }),
                state: { email }
            });
        } finally {
            setIsResendCode(false);
            setOTP("");
        }
    };

    if (!location?.state?.email) {
        <Redirect to={APP_ROUTES.BLS_FEEDBACK} />;
    }

    if (isAuthenticated) {
        return <Redirect to={APP_ROUTES.BLS_FEEDBACK} />;
    }

    return (
        <>
            <Helmet>
                <title>{PageTitles.OTP_VERIFICATION}</title>
            </Helmet>
            <AuthWrapper
            >
                <div className="bv-forget-wrap">
                    <Headings title={typography.OTP_VERIFICATION} subText={typography.RESET_PASSWORD} />

                    <form className="mt-5" onSubmit={handleSubmit}>
                        <h6 className="font-clr-theme-secondary mb-4 font-size-12 text-left p-0">
                        {typography.ENTER_ONE_TIME_PASSWORD_OTP}
                        </h6>
                        <Row>
                            <Col md={12}>
                                <Label>
                                {typography.ENTER_OTP}
                                </Label>
                                <CodeInput
                                    className="my-4 text-center"
                                    onChange={(e) => setOTP(e)}
                                />
                            </Col>
                        </Row>
                        <CustomButton
                            disabled={OTP.length != OTP_LENGTH}
                            loading={isLoading}
                            onClick={handleSubmit}
                            color="primary"
                            type={"submit"}
                            title={typography.SUBMIT}
                            className="w-100 mt-4"
                        />
                    </form>
                    <div
                        className="d-flex mt-4 align-items-center justify-content-center"
                       
                       
                    >
                        <p className="standard-typo-14-400"> {typography.DID_NOT_RECEIVE} </p>
                        <div className="standard-typo-14-600 ms-1 fw-600"  style={{'cursor': "pointer"}}  onClick={handleResendCode}>
                            {isResendCode ? typography.SENDING : typography.RESEND_NOW}
                        </div>
                    </div>
                    <div>
                        <Link
                            className="d-flex align-items-center justify-content-center my-4"
                            to={APP_ROUTES.LOGIN}
                        >
                            <p className="standard-typo-14-600"> {typography.BACK_TO_LOGIN} </p>
                        </Link>
                    </div>
                </div>
            </AuthWrapper>
            <CustomModal isOpen={showOtpModal} size="md">
                <div className="text-center">
                    <h2 className="font-clr-theme-secondary font-family-lexend">{typography.OTP_VERIFIED_SUCCESSFULLY}</h2>
                    <p className="my-4 font-family-lexend fw-500 font-size-14">
                        {typography.ONE_TIME_OTP_VERIFIED_SUCCESSFULLY} <br /> {typography.CONTINUE_TO_RESET_PASSWORD}
                    </p>
                    <CustomButton
                        color="primary"
                        title={typography.CONTINUE}
                        className="custom-btn-sm  mb-5"
                        onClick={() => {
                            history.push(
                                getParamsAttachedRoute(APP_ROUTES.RESET_PASSWORD, {
                                    code: codeAndToken?.code,
                                    tokenId: codeAndToken?.tokenUId
                                })
                            );
                        }}
                    />
                </div>
            </CustomModal>
        </>
    );
};

export default withRouter(ForgetVerifyCode);
