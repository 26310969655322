import { Col } from "reactstrap"
import { findOutClassStatBoxes } from "./Utility"
import typography from "./typography"
import { useEffect, useState } from "react";
import { getApiFetch, hasData } from "../../utils/commonUtils";
import { analyticalDataAPI } from "../../api/api.service";
import { LoaderFallbackUI } from "../../components/LoaderFallbackUI";

const STATE_BOXES = [
  {
      HEADING: typography.BLS_THERAPY_COUNT,
      BOTTOM_TEXT: typography.TOTAL_SESSION_TAKEN,
      key: "blsCount",
      COUNT: "0"
  },{
      HEADING: typography.TOTAL_RESPONSES,
      BOTTOM_TEXT: typography.SUBMITTED_BY_USER,
      key: "blsFeedbackCount",
      COUNT: "0"
  },
  {
      HEADING: typography.AVERAGE_RATING,
      BOTTOM_TEXT: typography.ALL_USER_FEEDBACK,
      key: "blsAvgRating",
      COUNT: "0",
      RATIO_COUNT: "10",
  }
]

export default function StatBoxes () {
  
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [analyticalData, setAnalyticalData] = useState([]);

  useEffect(() => {
    // fetching api
    if(!hasData(data) && !loading){
      let params = {blsCount:true,blsFeedbackCount:true,blsAvgRating:true}
      getApiFetch(setLoading, setData, analyticalDataAPI, undefined, params);
    }

    // updating data
    if(hasData(data)){
      STATE_BOXES.forEach( el => {
        el['COUNT'] = data[el.key]
      })
      setAnalyticalData(STATE_BOXES)
    }
  }, [data]);

  return(
    <>
        {
          loading && !hasData(data) ? (
            <LoaderFallbackUI
            className="loader-con"
            isLoader = {loading} 
             />
          ) : null
         } 
         {
          !loading && hasData(data) ? (
            analyticalData.length && analyticalData.map( (el, idx) => {
                  return (
                    <Col xl="4" md="6" sm="6" xs="12" className="mb-3" key={idx}>
                        <div className={`stat-boxes-con p-3 ${findOutClassStatBoxes(el.HEADING)}`} >
                            <p className="standard-typo-14-500-ld ft-white">{el.HEADING}</p>
                            <h1 className="ft-white stat-box-heading font-family-lexend">{el.COUNT}<span>{el?.RATIO_COUNT ? `/${el?.RATIO_COUNT}` : null}</span></h1>
                            <div className="d-flex justify-content-end ft-white">
                                <p className="standard-typo-12-400-ld ft-white">{el.BOTTOM_TEXT}</p>
                            </div>
                        </div>
                    </Col>
                  )
              })
        ) : null
       }
    </>
  )
}