export const APP_ROUTES = {
    // Authentication
    LOGIN: "/login",
    LOGOUT: "/logout",
    FORGET_PASSWORD: "/forgot-password",
    FORGET_VERIFY_CODE: "/forget-verify-code/:tokenId",
    RESET_PASSWORD: "/reset-password/:tokenId",
    REGISTER: "/register",
    EDIT_PROFILE: "/edit-profile",
    CHANGE_PASSWORD: "/change-password",
    // Dashboard
    BLS_FEEDBACK: "/bls-feedback",
    CUSTOMER_MANAGEMENT: "/customer-management",
    CUSTOMER_DETAILS: "/customer-management/customer-details"
};

export const getParamsAttachedRoute = (route, obj = {}) => {
    let keys = Object.keys(obj);

    if (keys?.length) {
        let objectKeys = keys;

        objectKeys.forEach(() => {
            route = route?.replace(new RegExp(/:([\d\w?])+/, "i"), (match) => {
                let formattedMatchedValue =
                    match[match.length - 1] === "?"
                        ? match.slice(1, match.length - 1)
                        : match.slice(1);
                return obj[formattedMatchedValue];
            });
        });
        return route;
    }
    return route;
};
