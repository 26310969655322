import { Col, Row } from "reactstrap";
import { useParams } from "react-router-dom";

import typography from "../typography";
import DetailsBox from "../Listing/DetailsBox";
import { Heading } from 'components/Heading';
import { profileDetailsConfig } from "./data";
import { useEffect, useState } from "react";
import { getApiFetch, hasData } from "../../../utils/commonUtils";
import { GetUserAffirmations } from "../../../api/api.service";

export default function Affirmations (){
  const { id } = useParams()
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [userAffirmationsData, setUserAffirmationsData] = useState([]);

  useEffect(() => {
    // fetching api
    if(!hasData(data) && !loading){
      let params = { userId: id }
      getApiFetch(setLoading, setData, GetUserAffirmations, undefined, params);
    }
  
  }, [id]);

  return(
    <>
      <div className="profile-details-con p-4 mt-3">
        <div className="mb-3">
          <Heading heading={typography.AFFIRMATION_DETAILS} />
        </div>
        <Row className="mx-auto">
          {data?.data?.length > 0 ? (data?.data?.map(el => {
            return(
              <>
                <Col md="6" xl="4" xxl="4" className=" mb-4 px-0 pe-0 pe-md-4 " key={el.id}>
                  <DetailsBox 
                  data={el}/>
                </Col>
              </> 
            )
          })): <div><p className="data-not-found-typo standard-typo-16-600">Data not found!</p></div>}
        </Row>
      </div>
    </>
  )
}