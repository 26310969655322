import React from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";

const CustomModal = ({
    isOpen,
    toggle,
    centered = true,
    children,
    className,
    onClose,
    size = "sm",
    contentClassName = "",
    modalBodyClasses = "",
    isFullScreen = false,
    headerClass="",
    closeIcon
}) => {
    return (
        <div className={`custom-modal ${className}`}>
            <Modal
                isOpen={isOpen}
                toggle={toggle}
                centered={centered}
                fullscreen={isFullScreen}
                contentClassName={contentClassName}
                size={size}
            >
                <ModalHeader className={`border-0 ms-auto ${headerClass}`}>
                    {onClose && closeIcon ? (
                        <img 
                        src={closeIcon}
                        onClick={onClose}
                        style={{'cursor': "pointer"}}
                         alt="icon"/>
                    ): (
                        <i
                            className="mdi mdi-close-circle font-size-24 clr-theme-secondary"
                            onClick={onClose}
                            role="button"
                        ></i>
                    )}
                </ModalHeader>
                <ModalBody className={` ${modalBodyClasses}`}>{children}</ModalBody>
            </Modal>
        </div>
    );
};

export default CustomModal;
