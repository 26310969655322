export const SortingOptions = [
    {
        label: "10",
        value: "10"
    },
    {
        label: "20",
        value: "20"
    },
    {
        label: "30",
        value: "30"
    }
];
