import { ME_REQUEST, ME_SUCCESS, ME_ERROR } from "../actions";

const initialState = {
    tokenVerified: false,
    isLoading: true
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ME_REQUEST:
            return { ...state, isLoading: true };
        case ME_SUCCESS:
            return { ...state, tokenVerified: true };
        case ME_ERROR:
            return { ...state, isLoading: false };
        default:
            return state;
    }
};
