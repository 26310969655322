import deleteModalImg from "../../assets/images/app/common/modal-delete-img.svg";

export let CONFIRMATION_MODAL_CONFIG = {
    CREATE: {
        Title: "Are you sure you wants to create ?",
        SubText: "",
        YesButtonText: "Continue",
        NoButtonText: "Cancel",
        ImageSrc: ""
    },
    REPLACE: {
        Title: "Are you sure you wants to edit ?",
        SubText: "",
        YesButtonText: "Continue",
        NoButtonText: "Cancel",
        ImageSrc: ""
    },
    UPDATE: {
        Title: "Are you sure you wants to update ?",
        SubText: "",
        YesButtonText: "Continue",
        NoButtonText: "Cancel",
        ImageSrc: ""
    },
    DELETE: {
        Title: "ARE YOU SURE YOU WANT TO DELETE ?",
        SubText: "",
        YesButtonText: "Delete",
        NoButtonText: "Cancel",
        ImageSrc: deleteModalImg
    },
    GENERAL: {
        Title: "Are you sure you wants to",
        SubText: "",
        YesButtonText: "Continue",
        NoButtonText: "Cancel",
        ImageSrc: ""
    }
};

export const getConfirmationModalConfig = (preBuildConfig, type = "", customConfig = null) => {
    let obj = preBuildConfig[type];

    if (customConfig) {
        obj = {
            Title: customConfig?.customTitle,
            SubText: customConfig?.customSubText,
            YesButtonText: customConfig?.customYesBtnText,
            NoButtonText: customConfig?.customNoBtnText,
            ImageSrc: customConfig?.customImageSrc
        };
        return obj;
    } else {
        return obj;
    }
};
