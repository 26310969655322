import { injectBaseConstantMethods } from "./BaseConstants";

const UserRoleType = {
    SuperAdmin: "super_admin",
    ClientAdmin: "client_admin",
    ClientManager: "client_manager",
    Auditor: "auditor",
    Merchandiser: "merchandiser",
    Resetter: "resetter",
    TeamLead: "team_lead"
};

const displayTextKeys = {
    [UserRoleType.SuperAdmin]: "Super Admin",
    [UserRoleType.ClientAdmin]: "Admin",
    [UserRoleType.ClientManager]: "Manager",
    [UserRoleType.Auditor]: "Auditor",
    [UserRoleType.Merchandiser]: "Merchandiser",
    [UserRoleType.Resetter]: "Resetter",
    [UserRoleType.TeamLead]: "TeamLead"
};

const labelClass = {
    [UserRoleType.SuperAdmin]: "success"
};

export default injectBaseConstantMethods(UserRoleType, displayTextKeys, labelClass);
