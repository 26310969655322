export default function ToggleInput ({
  trueContent= "",
  falseContent="",
  toggleHandler= () => {},
  isChecked,
  userId,
  isLoading=false
}) {
  return(
    <>
      <label className="switch">
        <input 
        type="checkbox" 
        checked={isChecked} 
        onClick={(e) => toggleHandler(userId)}
        disabled={isLoading}/>
        <span className="slider"></span>
        <span className="text on">{trueContent}</span>
        <span className="text off">{falseContent}</span>
      </label>
    </>
  )
}