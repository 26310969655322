import React, { useState } from "react";

import crossIcon from "assets/images/img-preview-cross.png";
import editIcon from "assets/images/app/common/aou-image-edit.svg";
import APP_CONFIG from "../../APP_CONFIG";
import "./Style.scss";
import { ImageTypes } from "../../constants";
import { imageUploadHandler } from "../../utils/mediaUtils";

const ImageBox = ({
    containerClasses = "",
    crossClasses = "",
    editClasses = "",
    imageClasses = "",
    isRemoveable = false,
    imageToPreview = "",
    placeholderImg = "",
    isEditable = true,
    handleRemoveImage = () => {},
    onImageUpload = () => {},
}) => {

    const [imageError, setImageError] = useState(undefined);
    const [imagePreview, setImagePreview] = useState(undefined);

    const initImgUpload = (e) => {
        let file = e.target.files[0];
        if (file) {
            imageUploadHandler({file, 
                imagePreviewCB: setImagePreview, 
                imageErrorCB: setImageError, 
                uploadImgCB: onImageUpload, 
                fileTypes: ImageTypes});
        }
    };

    const removeImageHandler = () => {
        setImagePreview(undefined);
        handleRemoveImage()
    }
    return (
        <>
        <div className="edit-profile-img-con">
            <div className={`preview-container ${containerClasses}`}>
                {/* Cross Button  */}
                {isRemoveable && imagePreview ? (
                    <img
                        role="button"
                        className={`cross-button ${crossClasses}`}
                        src={crossIcon}
                        onClick={removeImageHandler}
                    />
                ) : null}

                {isEditable && !imagePreview ? (
                    <>
                        <input
                            hidden
                            type="file"
                            accept=".jpg,.jpeg,.png,.pdf"
                            name="file"
                            id="img-input-id"
                            onChange={initImgUpload}
                            disabled={!isEditable}
                        />
                        <label
                            htmlFor="img-input-id"
                            className="place-order-image-con d-flex justify-content-center align-items-center case-detail-accordian-body-img size-modifier"
                        >
                            <img
                                className={`edit-button ${editClasses} ${
                                    isEditable ? "cursur--pointer" : ""
                                }`}
                                src={editIcon}
                                alt="edit icon"
                            />
                        </label>
                    </>
                ) : null}
                <img
                    src={imagePreview || imageToPreview || placeholderImg}
                    className={`preview-image ${imageClasses}`}
                    alt="file to upload"
                />
            </div>

        </div>
        {imageError && (
            <span className="d-block text-danger file-upload-error mt-1">{imageError}</span>
        )}
        </>
    );
};

export default ImageBox;
