
const typography = {
  BLS_THERAPY_COUNT: "BLS Therapy Count",
  TOTAL_SESSION_TAKEN: "Total Sessions Taken",
  TOTAL_RESPONSES: "Total Responses",
  SUBMITTED_BY_USER: "Submitted by users",
  AVERAGE_RATING: "Average Rating",
  ALL_USER_FEEDBACK: "All Users Feedback",
  CUSTOMER: "Customer",
  DATE: "Date",
  RATING: "Rating",
  FEEDBACK_FORM: "Feedback Form",
  FEEDBACK_DETAILS: "Feedback Details",
  AFFIRMATION_PHRASE: "Affirmation Phrase",
}

export default typography

