import { Get, Put, Post, Patch, Delete } from "./api.helper";
import { store } from "store";
import Config from "Config";
import { appendQueryParams } from "utils/urlUtils";
import { handleFetchError } from "../utils/errorHandler";

// ==== Authentication
export const Login = (bodyData) => {
    return Post({
        url: "/auth/login",
        body: bodyData,
        isAuthorized: false
    });
};

export const GetMe = () => {
    return Get({ url: "/user/me" });
};

export const ForgotPassword = (bodyData) => {
    return Post({ url: "/auth/forget-password", body: bodyData });
};

export const ResendOTP = (bodyData) => {
    return Post({ url: "/auth/resend-otp", body: bodyData });
};

export const ForgotPasswordVerification = (bodyData) => {
    return Post({
        url: "/auth/forget-password/verification",
        body: bodyData
    });
};

export const ResetPassword = (bodyData) => {
    return Post({ url: "/auth/reset-password", body: bodyData });
};

export const VerifyUser = (bodyData) => {
    return Post({
        url: "/",
        body: bodyData,
        isAuthorized: false
    });
};

export const SignUp = (bodyData) => {
    return Post({
        url: "/",
        body: bodyData,
        isAuthorized: false
    });
};

export const changePassword = (bodyData) => {
    return Post({
        url: "/auth/change-password",
        body: bodyData,
    })
}
// ALPHA LISTING API
export const customerListingApi = (params = {}, abortSignal = null) => {
    return Get({ url: "/users", params, abortSignal });
};

export const userUpdateStatus = ({ id, bodyData }) => {
    return Post({
        url: `/user/${id}/status`,
        body: bodyData
    });
};

export const GetUserById = (id) => {
    return Get({
        url: `/user/${id}`
    })
}

export const GetUserAffirmations = (params = {}, abortSignal = null) => {
    return Get({url: "/affirmations", params, abortSignal})
}

// BLS FEEDBACK

export const analyticalDataAPI = (params = {}, abortSignal = null) => {
    return Get({ url: "/analytics/summary", params, abortSignal });
};

export const blsListing = (params = {}, abortSignal = null) => {
    return Get({ url: "/bls", params, abortSignal });
};

export const updateUserProfile = ({id, bodyData}) => {
    return Patch({ url: `/user/${id}`, body: bodyData });
};

export const MediaUpload = (bodyData, abortSignal = null) => {
    const token = store.getState().userAuth.user.Token;

    const url = `${Config.env().API_URL}/media/upload`;

    return fetch(url, {
        headers: {
            authorization: token
        },
        body: bodyData,
        method: "POST"
    }).then(handleFetchError);
};


//========================
// Examples of Api Calling
//========================

// GET

// const GetApiExample = (params = {}, abortSignal = null) => {
//     return Get({ url: "/url", params, abortSignal });
// };

// // GET BY ID

// const GetByIdApiExample = (id) => {
//     return Get({ url: `/url/${id}` });
// };

// // POST
// const PostApiExample = (bodyData) => {
//     return Post({
//         url: "/url",
//         body: bodyData
//     });
// };
// // POST BY ID

// const PostByIdApiExample = ({ id, bodyData }) => {
//     return Post({
//         url: `/url${id}`,
//         body: bodyData
//     });
// };
// // PUT

// const PutApiExample = (bodyData) => {
//     return Put({
//         url: "/url",
//         body: bodyData
//     });
// };
// // PUT BY ID

// const PutByIdApiExample = ({ id, bodyData }) => {
//     return Put({
//         url: `/url${id}`,
//         body: bodyData
//     });
// };
// //  DELETE
// const DeleteApiExample = (id) => {
//     return Delete({
//         url: `/url/${id}`
//     });
// };
