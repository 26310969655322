import React from "react";

const Headings = ({ title = "Title", subText = "Subtext", isLogin = false }) => {
    return (
        <div className="d-flex flex-column align-items-center auth-heading-con">
            {
                isLogin ? (
                    <>
                    <p className="standard-heading-22-600 login-title m-0 p-0">{title}</p>
                    <h1 className="standard-heading-22-600 m-0 p-0">{subText}</h1>
                    </>
                ) : (
                    <>
                    <h1 className="standard-heading-22-600 m-0 p-0">{title}</h1>
                    <p className="standard-typo-14-400  m-0 p-0">{subText}</p>
                    </>
                   )
            }
        </div>
    );
};

export default Headings;
