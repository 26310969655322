import { Roles } from "../constants";
import { APP_ROUTES } from "../helpers/routeHelpers";
import CustomerManagementIconDark from '../assets/images/app/common/customer-mana-icon-dark.svg';
import FeedbackIconDark from '../assets/images/app/common/feedback-icon-dark.svg'

let { Admin, SuperAdmin } = Roles;

const SIDEBAR_CONFIG = [
    {
        title: "BLS Feedback",
        url: APP_ROUTES.BLS_FEEDBACK,
        icon:  null,
        menuImg: FeedbackIconDark,
        nestedMenu: null,
        permissions: [Admin]
    },
    {
        title: "Customer Management",
        url: APP_ROUTES.CUSTOMER_MANAGEMENT,
        icon: null,
        menuImg: CustomerManagementIconDark,
        nestedMenu: null,
        permissions: [Admin]
    }
];

export default SIDEBAR_CONFIG;
